import {Box, Typography} from '@mui/material';
import PostRer from "../../shared/components/PostRer";
import {useGetElectricityDataQuery, useGetRelayDataQuery} from "../../shared/api/api";
import {useEffect} from "react";
import * as React from "react";

const Electricity = () => {
    const {
        data: electricity,
        error: errorElectricity,
        isLoading: isLoadingElectricity,
        refetch: refetchElectricity
    } = useGetElectricityDataQuery();
    const {data: relay, error: errorRelay, isLoading: isLoadingRelay} = useGetRelayDataQuery();

    useEffect(() => {
        const interval = setInterval(() => {
            refetchElectricity();
        }, 600000);

        return () => clearInterval(interval);
    }, [refetchElectricity]);


    if (isLoadingElectricity || isLoadingRelay) return <div>Loading...</div>;
    if (errorElectricity || errorRelay) return <div>Error: {errorElectricity.message || errorRelay.message}</div>;

    if (isLoadingRelay) return <div>Loading...</div>;
    if (errorRelay) return <div>Error: {errorRelay.message}</div>;

    const defaultElectricityData = {
        voltage: 0,
        current: 0,
        power: 0,
        temperature: 0,
    };

    const electricityData = electricity?.reduce((acc, item) => {
        acc[item.postName] = item;
        return acc;
    }, {}) || {};

    const relayData = relay?.reduce((acc, item) => {
        if (!acc[item.post_name]) {
            acc[item.post_name] = [];
        }
        acc[item.post_name].push(item);
        return acc;
    }, {}) || {};

    const titles = [
        {title: 'dacha_1', name: 'Dacha 1'},
        {title: 'dacha_2', name: 'Dacha 2(MikroTik)'},
        {title: 'dementor1', name: 'Dementor 1'},
        {title: 'dementor2', name: 'Dementor 2'},
        {title: 'luka1', name: 'Luka 1'},
        {title: 'luka2', name: 'Luka 2'},
        {title: 'aqua', name: 'Aqua'},

    ];

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mt: 2,
                    mb: 2,
                    textDecoration: 'underline'
                }}>
                <Typography variant="h6" component="h1">Control Panel</Typography>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    gap: '16px',
                    padding: '16px',
                }}
            >
                {titles.map((titleItem, index) => (
                    <Box
                        key={index}
                        sx={{
                            flex: '1 1 calc(50% - 16px)',
                            maxWidth: 'calc(50% - 16px)',
                            minWidth: 'calc(50% - 16px)',
                            '@media (max-width: 600px)': {
                                flex: '1 1 100%',
                                maxWidth: '100%',
                                minWidth: '100%',
                            },
                        }}
                    >
                        <PostRer
                            name={titleItem.name}
                            electricityData={electricityData[titleItem.title] || defaultElectricityData}
                            relayData={relayData[titleItem.title] || []}
                        />
                    </Box>
                ))}
            </Box>
        </>
    );
};

export default Electricity;
